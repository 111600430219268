@use "sass:meta" as ---hr3ihs5iypp;$breakpoints: (
    xsmall: 319px,
    small: 320px,
    medium: 480px,
    large: 960px,
    xlarge: 1200px //xxlarge: 1300px
);


@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin page-bottom() {
    position: relative;
    height: 3.5rem;
    margin-top: -3.5rem;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin bp($name) {
    @if not map-has-key($breakpoints, $name) {
        @warn "Invalid breakpoint `#{$name}`.";
    }

    @else {
        @if map-get($breakpoints, $name) {
            @media (min-width: map-get($breakpoints, $name)) {
                @content;
            }
        }

        @else {
            @content;
        }
    }
}

//$property : css property being changed
//$value-start / $value-end : beginning and end values of property
//$bp-start / $bp-end : set to small and large breakpoints by defaults, but can be changed
@mixin spread-value($property, $value-start, $value-end, $bp-start: small, $bp-end: large) {

    @if type-of($value-start) !=number or type-of($value-end) !=number {
        @warn "Either $value-start or $value-end is not a number: `#{$value-start}` | `#{$value-end}`"
    }

    @else {
        #{$property}: #{$value-start};
        $value-distance: $value-end - $value-start;
        $bp-distance: get_bp($bp-end) - get_bp($bp-start);
        $bp-keys: map-keys($breakpoints);
        $bp-list: ();

        $i: index($bp-keys, $bp-start);

        @while $i <=length($bp-keys) and nth($bp-keys, $i) !=$bp-end {
            $i: $i +1;
            $bp-list: join($bp-list, nth($bp-keys, $i));
        }

        @each $key in $bp-list {
            $percentage: (get-bp($key) - get_bp($bp-start)) / $bp-distance;

            @include bp($key) {
                #{$property}: round-num(calc(($value-distance * $percentage ) + $value-start ));
            }
        }
    }
}
;@include ---hr3ihs5iypp.load-css("sass-embedded-legacy-load-done:2");