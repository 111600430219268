#google-sign-in {
    margin: auto;
    width: fit-content;
}

.msft-signin-item {
    display: flex;
    font-family: 'Arial', sans-serif;
    font-weight: 600;
    font-size: 14px;
    color: #5E5E5E;
    padding: 12px;
    width: 200px;
    border: 1px #8C8C8C;
    margin: 15px auto;
    max-height: 40px;
    background-color: #fff;
    justify-items: flex-start;
    border-radius: 3px;
    white-space: nowrap;
}

.msft-signin-item span {
    //padding-top: 5px;
    padding-left: 10px;
}

.msft-signin-item:hover {
    cursor: pointer;
    background-color: #dfdfdf;
}

.signin-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/*
#signin-button {
    margin-top: 20px;
    width: 260px;
}

#signin-input {
    padding-left: 15px;
    font-size: calc(14px + (2 * ((100vw - 320px) / 680)));
    width: 250px;
}
*/

.signin-redirect {
    margin-top: 1vh;
    background-color: white;
    color: var(--darkest-blue);
    font-family: var(--sans-serif);
    font-size: 14p;
    font-weight: 600;
    border: none;
    cursor: pointer;
    width: calc(200px + (6 * ((100vw - 320px) / 680)));
    text-decoration: none;
    padding: 5px 20px 5px 20px;
    margin: 5px;
    border-radius: 20px;
}

.signin-redirect:hover {
    color: var(--lightest-blue);
}

.signin-title {
    font-family: var(--sans-serif);
    font-size: 28px;
    font-weight: 600;
    line-height: 1.25;
    margin-bottom: 2vh;
    margin-top: 3vh;
    color: #fff;
}

.signin-panel {
    background: var(--darkest-blue);
    display: flex;
    flex-direction: column;
    width: 350px;
    max-width: "100%";
    min-height: fit-content;
    align-items: center;
    position: relative;
    margin: 50px auto 100px auto;
    padding: 3vmin;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    box-shadow: var(--box-shadow);
}

.signin-wrapper {
    padding: 25px;
    margin: 0 auto 0 auto;
}